import React from "react"
import TopHeaderMenu from "../../header/top-header-with-menu/topHeaderWithMenu"
import styles from "./header.module.css"

import { StructuredText } from "react-datocms";

const CampaignHeader = props => {
  return (
    <section className={styles.sectionContainer}>

      <TopHeaderMenu page={"themenseite"} url={props.url} />

      <div className={styles.textandImageContainer}>
        <div className={styles.textContainer}>
          <StructuredText data={props.HeaderText} />
        </div>

        <div className={styles.imagesContainer}>
          {props.Img1 &&
            <div
              className={styles.mainImage}
              style={{ backgroundImage: `url(${props.Img1})` }}
            />
          }

          {(props.Img2 || props.Img3) &&
            <div className={styles.img2AndImg3FlexContainer}>
              {props.Img2 &&
                <div
                  className={styles.secondaryImage2}
                  style={{ backgroundImage: `url(${props.Img2})` }}
                />
              }

              {props.Img3 &&
                <div
                    className={styles.certificateImg1}
                    style={{backgroundImage: `url(${props.Img3})`}}
                />
              }
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default CampaignHeader
