import React from 'react'
import styles from './component.module.css'

export default function SectionContainer(props) {
    return (
        <section className={`${props.sectionClass ?? ''} ${styles.campaignSection}`}>
            <div className={`${props.containerClass ?? ''} ${styles.sectionContainer}`}>
                {props.children}
            </div>
        </section>
    )
}
