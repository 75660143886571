import React from "react"
import SectionContainer from "../section-container"

import styles from "./component.module.css"

export default ({ headline, images, grid }) => {
  const [firstImage, ...otherImages] = images

  return (
    <SectionContainer
      containerClass={`${styles.imageFeed}${
        grid === true ? " " + styles.grid : ""
      }`}
    >
      {headline && (
        <div
          className={styles.headline}
          dangerouslySetInnerHTML={{ __html: headline }}
        />
      )}

      {!grid && (
        <div className={styles.imageContainer}>
          {images.map(image => {
            return (
              <div className={styles.col}>
                <img
                  key={image.id}
                  className={styles.image}
                  src={image.fluid.src}
                  alt={image.alt}
                  title={image.title}
                />
              </div>
            )
          })}
        </div>
      )}

      {grid && (
        <div className={styles.imageContainerGrid}>
          <div className={styles.col1}>
            <img
              src={firstImage.fluid.src}
              alt={firstImage.alt}
              title={firstImage.title}
            />
          </div>

          {otherImages.length >= 2 && (
            <div className={styles.col2}>
              <img
                src={otherImages[0].fluid.src}
                alt={otherImages[0].alt}
                title={otherImages[0].title}
              />
              <img
                src={otherImages[1].fluid.src}
                alt={otherImages[1].alt}
                title={otherImages[1].title}
              />
            </div>
          )}

          {otherImages.length >= 4 && (
            <div className={styles.col3}>
              <img
                src={otherImages[2].fluid.src}
                alt={otherImages[2].alt}
                title={otherImages[2].title}
              />
              <img
                src={otherImages[3].fluid.src}
                alt={otherImages[3].alt}
                title={otherImages[3].title}
              />
            </div>
          )}

          {otherImages.length >= 6 && (
            <div className={styles.col4}>
              <img
                src={otherImages[4].fluid.src}
                alt={otherImages[4].alt}
                title={otherImages[4].title}
              />
              <img
                src={otherImages[5].fluid.src}
                alt={otherImages[5].alt}
                title={otherImages[5].title}
              />
            </div>
          )}
        </div>
      )}
    </SectionContainer>
  )
}
