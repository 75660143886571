import React from 'react'
import SectionContainer from '../section-container'
import imageSrc from '../../../lib/image-src'

import styles from './component.module.css'

export default ({ headline, column1, column2, graphic }) => {
    return (
        <SectionContainer containerClass={styles.textCols}>
            <header className={styles.textColsHeader}>
                <div
                    className={styles.headline}
                    dangerouslySetInnerHTML={{ __html: headline }} />
                {graphic &&
                    <div className={styles.imageWrapper}>
                        <img src={imageSrc(graphic)} alt={graphic.alt} title={graphic.title} />
                    </div>}
            </header>

            <div className={styles.content}>
                <div dangerouslySetInnerHTML={{ __html: column1 }} />
                {column2 &&
                    <div className={styles.col2} dangerouslySetInnerHTML={{ __html: column2 }} />}
            </div>
        </SectionContainer>
    )
}
