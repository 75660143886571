import React from 'react'
import {graphql} from 'gatsby'
import { Helmet } from 'react-helmet'

import imageSrc from '../lib/image-src'

import CampaignHeader from '../components/themenseite-components/campaign-header/header'
import Footer from '../components/footer/footer'
import CookieConsent from '../components/cookie-consent'

import ImageText from '../components/campaign/image-text'
import ImageFeed from '../components/campaign/image-feed'
import TextColumns from '../components/campaign/text-columns'

import styles from '../styles/campaign-christmas.module.css'

export default ({ data, location }) => {
  const cookieConsentRef = React.createRef();

  const page = data.datoCmsCampaignPage;

  return (
    <>
      <Helmet htmlAttributes={{ lang: "de" }} defer={false}>

      </Helmet>

      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />

      <CampaignHeader
          url={location.href}
          HeaderText={page.headerText}
          Img1={imageSrc(page.headerImage)}
      />

      <main className={styles.main}>
        {page.content.map((el, i) => {
          const key = i.toString();
          switch (el.__typename) {
            case 'DatoCmsCampaignImageText':
              return (<ImageText key={key} {...el} />);
            case 'DatoCmsCampaignImageFeed':
              return (<ImageFeed key={key} {...el} />);
            case 'DatoCmsCampaignTextColumn':
              return (<TextColumns key={key} {...el} />);
            default:
              return (<section key={key} style={{color: 'red'}}>Element {el.__typename} can not be rendered</section>);
          }
        })}
      </main>

      <Footer cookieConsentRef={cookieConsentRef}/>
    </>
  )
}

export const query = graphql`
    query($id: String!) {
        datoCmsCampaignPage(id: {eq: $id}) {
            seoMetaTags {
                tags
            }
            headerImage {
                alt
                url
                fluid(imgixParams: {auto: "format", dpr: 1.5, w: "1040"}) {
                    src
                }
            }
            headerText {
                blocks
                links
                value
            }
            content {
                ... on DatoCmsCampaignImageText {
                    __typename
                    image {
                        alt
                        title
                        url
                        fluid {
                            src
                        }
                    }
                    text
                    alignment
                }
                ... on DatoCmsCampaignTextColumn {
                    __typename
                    headline
                    column1
                    column2
                    graphic {
                        url
                        fluid(imgixParams: {auto: "format", dpr: 1.5, w: "440", fit: "crop"}) {
                            src
                        }
                    }
                }
                ... on DatoCmsCampaignImageFeed {
                    id
                    __typename
                    headline
                    grid
                    images {
                        alt
                        title
                        url
                        fluid(imgixParams: {auto: "format", dpr: 1.5, h: "660", w: "440", fit: "crop", crop: "faces"}) {
                            src
                        }
                    }
                }
            }
        }
    }
`
