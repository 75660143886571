import React from 'react'
import SectionContainer from '../section-container'
import imageSrc from '../../../lib/image-src'

import styles from './component.module.css'

export default ({ image, text, alignment }) => (
    <SectionContainer >
        <div className={`${styles.imageText}${alignment === true ? ' ' + styles.rev : ''} section-content-wrapper`}>
            <div className={styles.imageWrapper}>
                <img src={imageSrc(image)} alt={image.alt} title={image.title} />
            </div>
            <div dangerouslySetInnerHTML={{__html: text}} />
        </div>
    </SectionContainer>
)
